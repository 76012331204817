.root {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  background-color: rgb(202, 202, 202);
}

.inputs {
  display: flex;
}

.button {
  padding-right: 10px;
}
