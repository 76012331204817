.root {
  padding: 10px;
  margin: 10px;
}

.form {
  display: flex;
  margin: 10px;
  justify-content: center;
}

.json {
  text-align: left;
  font-size: large;
  border: 2px solid gray;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  background-color: rgb(246, 246, 246);
}
