.root {
    padding: 10px;
    margin: 10px;
}

.form {
    display: flex;
    margin: 10px;
    justify-content: center;
}

.blog {
    max-width: 75%;
}
