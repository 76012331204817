.root {
    padding: 10px;
    margin: 10px;
}

.form {
    display: flex;
    margin: 10px;
    justify-content: center;
}

.longtext {
    width: 50rem;
}

.uuid {
    width: 300rem;
}

.button {
    padding-right: 10px;
}
.profiles {
    display: flex;
    margin: 0px auto;
}
