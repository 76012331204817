.root {
    padding: 10px;
    margin: 10px;
}

.form {
    margin-left: 10px;
    margin-top: 10px;
}

.textbtnrow {
    display: flex;
    padding-bottom: 10px;
}

.files {
    margin-left: 10px;
    padding: 10px;
}
