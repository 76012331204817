.root {
    padding: 10px;
}

.form {
    display: flex;
    justify-content: center;
}

.results {
    justify-content: left;
    padding-bottom: 10px;
}

.result {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    background-color: rgb(202, 202, 202);
}

.link {
    color: darkblue;
}

.clear {
    font-size: x-small;
    background-color: darkblue;
    color: white;
    border-radius: 10%;
    padding: 5px;
    display: inline;
}

.handler {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    align-items: flex-end;
}

.retriever_select {
    margin-top: 10px;
}

.search {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    align-items: flex-end;
}

.button {
    padding-right: 10px;
}

.button2 {
    padding-top: 10px;
}

.list {
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
}

.idSearch {
    margin-right: 10px;
    padding: 10px;
    background-color: rgb(202, 202, 202);
    border-radius: 50%;
}

.idCell {
    display: flex;
}

tr:hover {
    background-color: rgb(244, 247, 255);
}
