.root {
    padding: 10px;
    margin: 10px;
}

.unify {
    display: flex;
    margin: 10px;
    justify-content: center;
}
.form {
    margin-left: 5px;
    margin-top: 5px;
}

.blog {
    max-width: 75%;
}

.csvFile {
    margin: 5px;
    padding: 5px;
    border: 1px solid lightgray;
    border-radius: 10px;
}
.csvFile:hover {
    box-shadow: 0 0 7px lightgray;
}

.csvRowItems {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px;
}
.csvRow {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px;
    padding: 5px;
    border: 1px solid lightgray;
    border-radius: 10px;
}
.csvRow:hover {
    box-shadow: 0 0 7px lightgray;
}

.fixedColumn {
    display: flex;
}

.newFixedColumn {
    display: flex;
}
