.root {
  padding: 10px;
}

.form {
  display: flex;
  justify-content: center;
}

.search {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  align-items: flex-end;
}

.button {
  padding-right: 10px;
}

.list {
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
}

.idSearch {
  margin-right: 10px;
  padding: 10px;
  background-color: rgb(202, 202, 202);
  border-radius: 50%;
}

.idCell {
  display: flex;
}
